import Vue from 'vue'

import App from './App.vue'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import router from './router'

Vue.config.productionTip = false
Vue.use(Toast, {
  timeout: 8000,
  position: 'bottom-center'
})

new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')
