<template>
  <div id="app">
    <!-- <img
      alt="Vue logo"
      src="./assets/logo.png"
    > -->
    <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->

    <!-- <NavList
      :btn-connect="btnConnect"
      @handleCallBack="upBtnConnect"
    />
    <Atlantis @handleCallBack="upBtnConnect" /> -->

    <router-view
      :btn-connect="btnConnect"
      @handleCallBack="upBtnConnect"
    />
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  data() {
    return {
      btnConnect: 'Connect'
    }
  },
  async mounted() {
    const provider = window.ethereum
    if (typeof provider !== 'undefined') {
      const myaccount = await provider.request({
        method: 'eth_accounts'
      })

      if (myaccount.length === 1) {
        this.btnConnect = myaccount[0].slice(0, 6) + '...'
      }
      provider.on('accountsChanged', (val) => {
        if (val.length === 0) {
          this.btnConnect = 'Connect'
        }
      })
    } else {
      console.log('MetaMask is not installed!')
    }
  },
  methods: {
    upBtnConnect(res) {
      this.btnConnect = res
    }
  }
}
</script>

<style>
/*#app {*/
/*  font-family: Avenir, Helvetica, Arial, sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  text-align: center;*/
/*  color: #2c3e50;*/
/*  margin-top: 60px;*/
/*}*/
</style>
