import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/IndexView.vue'),
  },
  {
    path: '/index',
    name: 'Index',
    component: () => import('../views/IndexView.vue'),
  },
  {
    path: '/index_tw',
    name: 'IndexTW',
    component: () => import('../views/IndexViewTW.vue'),
  },
  {
    path: '/detail',
    name: 'Mint',
    component: () => import('../views/MintView.vue'),
  },
  {
    path: '/detail_tw',
    name: 'MintTW',
    component: () => import('../views/MintViewTW.vue'),
  },
  {
    path: '/arts',
    name: 'arts',
    component: () => import('../views/ArtsView.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
